import { watch } from 'vue'
import * as Sentry from '@sentry/vue'
import { useRoute } from 'vue-router'
import { getLocalStorage } from '~/services/LocalStorage/localStorage'

export default defineNuxtPlugin(() => {
  const keyToLog = 'agentum_token'
  const cookie = getLocalStorage('agentum_token')

  const route = useRoute()
  let lastStack: null = null
  watch(
    () => cookie,
    (newValue, oldValue) => {
      const timestamp = new Date().toISOString()
      const eventData = {
        keyToLog,
        newValue,
        oldValue,
        timestamp,
        route: route.fullPath,
        stackTrace: lastStack,
      }
      try {
        throw new Error('stack trace')
      } catch (error) {
        lastStack = error.stack
      }
      if (newValue === null || newValue === undefined) {
        Sentry.captureMessage('Removing cookie', {
          level: 'info',
          tags: { event: 'Removing cookie' },
          extra: eventData,
        })
      } else {
        Sentry.captureMessage('Setting cookie', {
          level: 'info',
          tags: { event: 'Setting cookie' },
          extra: eventData,
        })
      }
    },
    { immediate: true },
  )
})
