<template>
  <div class="page-404">
    <div class="content">
      <h1>404</h1>
      <p>{{ 'Страница не найдена' }}</p>
      <button @click="redirectToHome">На домашнюю страницу</button>
    </div>
  </div>
</template>

<script setup>
const router = useRouter()

const redirectToHome = () => {
    router.push('/')
}
</script>

<style scoped lang="scss">
.page-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(45deg, #ff4500, #ffa07a);
  animation: gradientAnimation 5s linear infinite alternate;

  .content {
    text-align: center;
    color: #fff;

    h1 {
      font-size: 4rem;
    }

    p {
      font-size: 1.5rem;
    }

    button {
      margin-top: 1rem;
      padding: 0.5rem 1rem;
      font-size: 1.2rem;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
</style>
