import revive_payload_client_4sVQNw7RlN from "/application/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/application/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/application/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/application/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/application/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/application/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import quasar_plugin_G9rpXFQbul from "/application/.nuxt/quasar-plugin.mjs";
import provide_4gUmUdUSZU from "/application/node_modules/nuxt-quasar-ui/dist/runtime/provide.mjs";
import chunk_reload_client_UciE0i6zes from "/application/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/application/node_modules/nuxt/dist/app/plugins/payload.client.js";
import lottie_q1asFBKAuo from "/application/plugins/lottie.ts";
import cookies_logger_DN1JkyMntR from "/application/plugins/cookies-logger.ts";
import maska_UHaKf2z1iQ from "/application/plugins/maska.ts";
import sentry_3AyO8nEfhE from "/application/plugins/sentry.ts";
import useSwipe_FaCEAoSJmE from "/application/plugins/useSwipe.ts";
import yandex_metrika_xjvrx4e2k4 from "/application/plugins/yandex-metrika.ts";
import tawk_messenger_c8p3UkeVab from "/application/plugins/tawk_messenger.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  quasar_plugin_G9rpXFQbul,
  provide_4gUmUdUSZU,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  lottie_q1asFBKAuo,
  cookies_logger_DN1JkyMntR,
  maska_UHaKf2z1iQ,
  sentry_3AyO8nEfhE,
  useSwipe_FaCEAoSJmE,
  yandex_metrika_xjvrx4e2k4,
  tawk_messenger_c8p3UkeVab
]