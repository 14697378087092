import TawkMessngerVue from '@tawk.to/tawk-messenger-vue-3'

export default defineNuxtPlugin({
  name: 'tawk',
  enforce: 'post',
  async setup(nuxtApp) {
    nuxtApp.vueApp.use(TawkMessngerVue, {
      propertyId: '65faa8ae1ec1082f04d93e94',
      widgetId: '1hpdi5r94',
    })
  },
  env: {
    islands: true,
  },
})
