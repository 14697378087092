import { ref, computed, useHead } from "#imports"
import { defineNuxtPlugin } from "#app"
import Quasar from "quasar/src/vue-plugin.js"
import Notify from "quasar/src/plugins/Notify.js"
import AppVisibility from "quasar/src/plugins/AppVisibility.js"
import Dialog from "quasar/src/plugins/Dialog.js"
import lang from "quasar/lang/ru"
import iconSet from "quasar/icon-set/material-icons"

export default defineNuxtPlugin((nuxt) => {




  nuxt.vueApp.use(Quasar, {
    lang,
    iconSet,
    plugins: {Notify,AppVisibility,Dialog},
    config: {},
  })
})