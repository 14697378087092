import Vue3TouchEvents from 'vue3-touch-events'

export default defineNuxtPlugin({
  name: 'useSwipe',
  enforce: 'post',
  async setup(nuxtApp) {
    nuxtApp.vueApp.use(Vue3TouchEvents)
  },
  env: {
    islands: true,
  },
})
